/* eslint-disable max-lines */
import React from 'react';
import { FeatureFlagService } from '@delta-defense/feature-flag-service/service/module';
import { Field } from '../../core/domain/transaction/field';
import { FieldValidation } from '../../core/domain/transaction/fieldValidation';
import { ITransaction } from '../../core/domain/transaction/ITransaction';
import { Step } from '../../core/domain/transaction/step';
import { Transaction } from '../../core/domain/transaction/transaction';
import { Regex } from '../../core/enums/regex';
import { Analytics, Locations } from '../../core/services/analytics/module';
import { Auth, Subscriptions, Zuora } from '../../core/services/module';
import { ElegantSteps } from '../elegant';
import { FieldTypes } from '../fieldTypes';
import { conditional, email, maxLength, minLength, pattern, required } from '../fieldValidations/module';
import { PaymentFields } from './default/paymentFields';
import styles from '../../pages/signup/payment.module.scss';
import { SupportPhoneLink } from '../../components/SupportPhoneLink/SupportPhoneLink';
import { PromoTypes } from '../../core/enums/module';
import { PaymentStepResultKeys } from './default/module';

export enum ElegantFields {
	Email = 'email',
	FirstName = 'firstName',
	LastName = 'lastName',
	ShippingAddress = 'shippingAddress',
	ShippingCity = 'shippingCity',
	ShippingState = 'shippingState',
	ShippingZip = 'shippingZip',
	Phone = 'phone',
	BillingSameAsShipping = 'billingSameAsShipping',
	BillingAddress = 'billingAddress',
	BillingCity = 'billingCity',
	BillingState = 'billingState',
	BillingZip = 'billingZip',
	BillingAddressTwo = 'billingAddressTwo',
	LevelSelection = 'levelSelection',
	AddSpouse = 'addSpouse',
	SpouseFirstName = 'spouseFirstName',
	SpouseLastName = 'spouseLastName',
	BillingFrequency = 'billingFrequency',
	Code = 'code',
	Password = 'password',
	PasswordConfirm = 'passwordConfirm',
	Dummy = 'dummy'
}

const isBillingDifferentFromShipping = (transaction = Transaction.Instance()) => {
	return transaction.Steps[ElegantSteps.Elegant].Fields[ElegantFields.BillingSameAsShipping].GetValue() !== 'checked';
};

const billingValidations: (validations: FieldValidation[], transaction?: ITransaction) => FieldValidation[] = (
	validations,
	transaction
) => {
	return validations.map((validation) => {
		return conditional(() => isBillingDifferentFromShipping(transaction), validation);
	});
};

export const isAddingSpouse = (transaction = Transaction.Instance()) => {
	return transaction.Steps[ElegantSteps.Elegant].Fields[ElegantFields.AddSpouse].GetValue() === 'checked';
};

export const spouseValidations: (validations: FieldValidation[]) => FieldValidation[] = (validations) => {
	return validations.map((validation) => {
		return conditional(() => isAddingSpouse(), validation);
	});
};

export const requiredForPromo = (promoType: PromoTypes, transaction?: ITransaction): FieldValidation =>
	conditional(() => !!transaction?.IncludesPromo(promoType), required);

export const deltaTermsValidationMessage = "You must agree to Delta Defense's terms and conditions to continue.";
export const promoTermsValidationMessage = 'You must agree to the promotional terms and conditions to continue.';
const oneDollarVerificationChargeCopy =
	// eslint-disable-next-line max-len
	'After purchase, you may receive a $1 charge on your credit or debit card. This is a verification charge to ensure funds. This $1 charge will pend on account and drop off per bank waiting period.';

export type ElegantResult = {};

export const Elegant = (
	_auth = Auth.Instance(),
	_analytics = Analytics.Instance(),
	_subscriptions = Subscriptions.Instance(),
	_featureFlag = FeatureFlagService.Instance(),
	transaction?: ITransaction,
	zuora = Zuora.Instance()
) =>
	new Step<ElegantResult>(
		{
			[ElegantFields.Email]: new Field('Email', 'Email Address *', FieldTypes.Email, 'A valid email address is required.', [
				required,
				email
			]),
			[ElegantFields.Code]: new Field(
				ElegantFields.Code,
				'Verification Code',
				FieldTypes.VerificationCode,
				'A six digit verification code is required.',
				[required, minLength(6), maxLength(6)]
			),
			[ElegantFields.Password]: new Field(ElegantFields.Password, 'Password', FieldTypes.Password, 'A password is required.', [required]),
			[ElegantFields.FirstName]: new Field(ElegantFields.FirstName, 'First Name *', FieldTypes.Text, 'First name is required.', [
				required,
				maxLength(40)
			]),
			[ElegantFields.LastName]: new Field(ElegantFields.LastName, 'Last Name *', FieldTypes.Text, 'Last name is required.', [
				required,
				maxLength(80)
			]),
			[ElegantFields.ShippingAddress]: new Field(
				ElegantFields.ShippingAddress,
				'Street Address *',
				FieldTypes.AddressLine1,
				'Street Address is required.',
				[required, maxLength(255)]
			),
			[ElegantFields.ShippingCity]: new Field(ElegantFields.ShippingCity, 'City *', FieldTypes.City, 'City is required.', [
				required,
				maxLength(40)
			]),
			[ElegantFields.ShippingState]: new Field(ElegantFields.ShippingState, 'State *', FieldTypes.StateSelect, 'State is required.', [
				required
			]),
			[ElegantFields.ShippingZip]: new Field(
				ElegantFields.ShippingZip,
				'ZIP Code *',
				FieldTypes.PostalCode,
				'5 digit ZIP Code is required.',
				[required, pattern(Regex.ZipCode)]
			),
			[ElegantFields.Phone]: new Field(ElegantFields.Phone, 'Phone Number', FieldTypes.Phone, 'Enter a 10 digit phone number.', [
				pattern(Regex.Phone)
			]),
			[ElegantFields.BillingSameAsShipping]: new Field(
				ElegantFields.BillingSameAsShipping,
				'Information is the same as billing',
				FieldTypes.Checkbox,
				'',
				[]
			),
			[ElegantFields.BillingAddress]: new Field(
				ElegantFields.BillingAddress,
				'Street Address *',
				FieldTypes.AddressLine1,
				'Street Address is required.',
				billingValidations([required, maxLength(255)], transaction)
			),
			[ElegantFields.BillingAddressTwo]: new Field(
				ElegantFields.BillingAddressTwo,
				'Street Address 2',
				FieldTypes.AddressLine2,
				'Street Address 2 cannot exceed 255 characters.',
				billingValidations([maxLength(255)], transaction)
			),
			[ElegantFields.BillingCity]: new Field(
				ElegantFields.BillingCity,
				'City *',
				FieldTypes.City,
				'City is required.',
				billingValidations([required, maxLength(40)], transaction)
			),
			[ElegantFields.BillingState]: new Field(
				ElegantFields.BillingState,
				'State *',
				FieldTypes.StateSelect,
				'State is required.',
				billingValidations([required], transaction)
			),
			[ElegantFields.BillingZip]: new Field(
				ElegantFields.BillingZip,
				'ZIP Code *',
				FieldTypes.PostalCode,
				'5 digit ZIP Code is required.',
				billingValidations([required, pattern(Regex.ZipCode)], transaction)
			),
			[ElegantFields.LevelSelection]: new Field(ElegantFields.LevelSelection, 'Level Selection', FieldTypes.SimpleLevel, '', []),
			[ElegantFields.AddSpouse]: new Field(ElegantFields.AddSpouse, 'Yes Please', FieldTypes.Checkbox, '', []),
			[ElegantFields.SpouseFirstName]: new Field(
				ElegantFields.SpouseFirstName,
				'Spouse First Name *',
				FieldTypes.Text,
				"Spouse's first name is required.",
				spouseValidations([required, maxLength(40)])
			),
			[ElegantFields.SpouseLastName]: new Field(
				ElegantFields.SpouseLastName,
				'Spouse Last Name *',
				FieldTypes.Text,
				"Spouse's last name is required.",
				spouseValidations([required, maxLength(80)])
			),
			[ElegantFields.BillingFrequency]: new Field(ElegantFields.BillingFrequency, '', FieldTypes.BillingFrequency, '', []),

			[PaymentFields.MembershipTerms]: new Field(
				PaymentFields.MembershipTerms,
				(
					<p className={styles.textSmall}>
						I represent and acknowledge that I have read, fully understand, and agree to be bound by USCCA’s{' '}
						<a
							onClick={() => {
								// Analytics.Instance().TrackInteraction({
								// 	event_name: InteractionEventNames.LinkClick,
								// 	events: {
								// 		click_text: 'Membership Agreement Terms',
								// 		form_field: FormFields.AddPaymentInfo,
								// 		location: Locations.Content,
								// 		sub_location: SubLocations.Step4
								// 	},
								// 	step: Steps.Four
								// });
							}}
							href="https://www.usccamemberterms.com/"
							target="_blank"
						>
							Membership Agreement Terms
						</a>{' '}
						and the warranties contained therein. I certify and warrant that I am at least 18 years of age and that all of the information
						contained in my Application is true and correct. I understand that I can cancel at any time by simply calling Delta Defense at{' '}
						<SupportPhoneLink location={Locations.Content} />.
						<p className={styles.activationNotice}>
							By activating my membership today, I authorize Delta Defense to automatically renew my membership charged to the method of
							payment provided.
						</p>
						<p>{oneDollarVerificationChargeCopy}</p>
					</p>
				),
				FieldTypes.Checkbox,
				'',
				[conditional(() => !!transaction?.IsMembershipPurchase(), required)]
			),
			[PaymentFields.CCMTerms]: new Field(
				PaymentFields.CCMTerms,
				(
					<>
						<p className={styles.textSmall}>
							I certify and warrant that I am at least 18 years of age. I understand that I can cancel at any time by simply calling Delta
							Defense at <SupportPhoneLink />. By activating my subscription today, I authorize Delta Defense to automatically renew my
							subscription charged to the method of payment provided.
						</p>
						<p>{oneDollarVerificationChargeCopy}</p>
					</>
				),
				FieldTypes.Checkbox,
				'',
				[conditional(() => !!transaction?.IsCCMPurchase(), required)]
			),
			[PaymentFields.SigSauerGeneralTerms]: new Field(
				PaymentFields.SigSauerGeneralTerms,
				(
					<p className={styles.textSmall}>
						By checking this box I acknowledge that I am purchasing a USCCA membership in good faith and am eligible for this promotional
						offer as a result of having purchased a SIG SAUER firearm after November 1st, 2020. Please see{' '}
						<a href="https://www.usconcealedcarry.com/uscca-info/offer/sig-uscca/fine-print/" target="_blank">
							Official Rules
						</a>{' '}
						for more.
					</p>
				),
				FieldTypes.Checkbox,
				promoTermsValidationMessage,
				[requiredForPromo(PromoTypes.SigSauerAmmo, transaction), requiredForPromo(PromoTypes.SigSauerOptic, transaction)]
			),
			[PaymentFields.SigSauerAmmoTerms]: new Field(
				PaymentFields.SigSauerAmmoTerms,
				(
					<p className={styles.textSmall}>
						I authorize Delta Defense LLC to charge the credit or debit card on file and impose and collect the MSRP payment of $150.00 for
						the promotional item in the event that: 1) I cancel my USCCA Membership pursuant to the USCCA 365 day money back guarantee; or
						2) I cannot show proof of purchase of a SIG SAUER firearm that was eligible for this offer.
					</p>
				),
				FieldTypes.Checkbox,
				promoTermsValidationMessage,
				[requiredForPromo(PromoTypes.SigSauerAmmo, transaction)]
			),
			[PaymentFields.SigSauerOpticTerms]: new Field(
				PaymentFields.SigSauerOpticTerms,
				(
					<p className={styles.textSmall}>
						I authorize Delta Defense LLC to charge the credit or debit card on file and impose and collect the MSRP payment of $219.99 for
						the promotional item in the event that: 1) I cancel my USCCA Membership pursuant to the USCCA 365 day money back guarantee; or
						2) I cannot show proof of purchase of a SIG SAUER firearm that was eligible for this offer.
					</p>
				),
				FieldTypes.Checkbox,
				promoTermsValidationMessage,
				[requiredForPromo(PromoTypes.SigSauerOptic, transaction)]
			),
			[PaymentFields.BerettaGeneralTerms]: new Field(
				PaymentFields.BerettaGeneralTerms,
				(
					<p className={styles.textSmall}>
						By checking this box I acknowledge that I am purchasing a USCCA membership in good faith and am eligible for this promotional
						offer as a result of having purchased a Beretta firearm after November 1st, 2021. Please see{' '}
						<a href="http://www.usconcealedcarry.com/uscca-info/offer/beretta-uscca/fine-print/" target="_blank">
							Official Rules
						</a>{' '}
						for more.
					</p>
				),
				FieldTypes.Checkbox,
				promoTermsValidationMessage,
				[requiredForPromo(PromoTypes.Beretta250, transaction)]
			),
			[PaymentFields.Beretta250Terms]: new Field(
				PaymentFields.Beretta250Terms,
				(
					<p className={styles.textSmall}>
						I authorize Delta Defense LLC to charge the credit or debit card on file and impose and collect the MSRP payment of $250 for the
						promotional item in the event that: 1) I cancel my USCCA Membership pursuant to the USCCA 365 day money back guarantee; or 2) I
						cannot show proof of purchase of a Beretta firearm that was eligible for this offer.
					</p>
				),
				FieldTypes.Checkbox,
				promoTermsValidationMessage,
				[requiredForPromo(PromoTypes.Beretta250, transaction)]
			),
			[PaymentFields.GiftCardGeneralTerms]: new Field(
				PaymentFields.GiftCardGeneralTerms,
				(
					<p className={styles.textSmall}>
						I agree that if I cancel my membership, the USCCA 365 day money-back guarantee will reimburse me the full amount I have paid
						towards my USCCA membership, but that upon cancellation I may be responsible to pay for the promotional item associated with
						this offer.
					</p>
				),
				FieldTypes.Checkbox,
				promoTermsValidationMessage,
				[requiredForPromo(PromoTypes.GiftCard75, transaction)]
			),
			[PaymentFields.GiftCard75Terms]: new Field(
				PaymentFields.GiftCard75Terms,
				(
					<p className={styles.textSmall}>
						I authorize Delta Defense LLC to charge the credit or debit card on file and impose and collect the payment of $75.00 for the
						promotional item in the event that I cancel my USCCA Membership pursuant to the USCCA 365 day money back guarantee.
					</p>
				),
				FieldTypes.Checkbox,
				promoTermsValidationMessage,
				[requiredForPromo(PromoTypes.GiftCard75, transaction)]
			),
			[PaymentFields.BrantleyGilbertTerms]: new Field(
				PaymentFields.BrantleyGilbertTerms,
				(
					<p className={styles.textSmall}>
						I authorize Delta Defense LLC to charge the credit or debit card on file and impose and collect the MSRP payment of $89.99 (BG
						Selects Premium box) in the event that: I cancel my USCCA Membership pursuant to the USCCA 365 day money back guarantee.
					</p>
				),
				FieldTypes.Checkbox,
				promoTermsValidationMessage,
				[requiredForPromo(PromoTypes.BrantleyGilbert, transaction)]
			),
			[PaymentFields.UseCardOnFile]: new Field(PaymentFields.UseCardOnFile, '', FieldTypes.UseCardOnFile, '', []),
			[PaymentFields.PewPewTerms]: new Field(
				PaymentFields.PewPewTerms,
				(
					<p className={styles.textSmall}>
						Please note that your information will be shared with Pew Pew Tactical for the purposes of redeeming your free gift.
					</p>
				),
				FieldTypes.Checkbox,
				promoTermsValidationMessage,
				[requiredForPromo(PromoTypes.PewPew, transaction)]
			),
			[PaymentFields.PrimaryArmsOptic]: new Field(
				PaymentFields.PrimaryArmsOptic,
				(
					<>
						<p className={styles.textSmall}>
							I authorize that if I cancel my membership, the USCCA 365 day money-back guarantee will reimburse the full amount I have paid
							towards my USCCA membership for the year, but that upon cancellation I may be responsible to pay for the promotional item
							associated with this offer.
						</p>
						<p className={styles.textSmall}>
							By checking this box I authorize Delta Defense LLC to charge the credit or debit card on file and collect the MSRP payment of
							$149.99 for the promotional item in the event that: I cancel my USCCA Membership pursuant to the USCCA 365 day money back
							guarantee
						</p>
					</>
				),
				FieldTypes.Checkbox,
				promoTermsValidationMessage,
				[requiredForPromo(PromoTypes.PrimaryArmsOptic, transaction)]
			),
			[PaymentFields.SigSauerGiftCard]: new Field(
				PaymentFields.SigSauerGiftCard,
				(
					<>
						<p className={styles.textSmall}>
							I authorize Delta Defense LLC to charge the credit or debit card on file and impose and collect the MSRP payment of $150 for
							the promotional item in the event that: 1) I cancel my USCCA Membership pursuant to the USCCA 365 day money back guarantee; or
							2) I cannot show proof of purchase of a Beretta firearm that was eligible for this offer.
						</p>
						<p className={styles.textSmall}>
							I authorize Delta Defense LLC to charge the credit or debit card on file and impose and collect the MSRP payment of $150.00
							for the promotional item in the event that: 1) I cancel my USCCA Membership pursuant to the USCCA 365 day money back
							guarantee; or 2) I cannot show proof of purchase of a SIG SAUER firearm that was eligible for this offer.
						</p>
					</>
				),
				FieldTypes.Checkbox,
				promoTermsValidationMessage,
				[requiredForPromo(PromoTypes.SigSauerGiftCard, transaction)]
			)
		},
		async () => {
			const clientPaymentValidationSuccessful = await zuora.Validate();

			return {
				wasSuccessful: true,
				[PaymentStepResultKeys.ClientPaymentValidationSuccessful]: clientPaymentValidationSuccessful
			};
		}
	);
