/* eslint-disable no-console */
import { safelyExecute } from '../../../utilities/safelyExecute';
import { Analytics, IAnalytics, Steps, InternalEventNames } from '../analytics/module';
import Environments from '@delta-defense/client-environments';
import { Apis } from '@delta-defense/client-environments/environments/enums/apis';
import { IRest, Rest } from '../rest/rest';
import {
	MessageCallbackFunction,
	RenderParams,
	CardPrepopulateFields,
	ZuoraCallbackEventData,
	ErrorMessageCallback,
	AchPrepopulateFields
} from './zuoraTypes';

export const NotConfiguredError = 'You must configure Zuora before rendering a payment form.';
const submissionDuration = 15000;

export interface IZuora {
	Configure(successCallback: MessageCallbackFunction, errorCallback: ErrorMessageCallback);
	Render(params: RenderParams, prepopulateFields: CardPrepopulateFields | AchPrepopulateFields);
	RenderApplePay(accountId: string, amount: string): Promise<void>;
	AddMessageCallback(): void;
	Validate(): Promise<boolean>;
	Submit(): void;
}

export class Zuora implements IZuora {
	private static instance: IZuora | null = null;
	public static Instance(
		mainWindow: Window = globalThis.window,
		analytics = Analytics.Instance(),
		rest = Rest.Instance(),
		paymentsProxyEndpoint = Environments.getProxyEndpointForApi(Apis.Payments)
	): IZuora {
		return this.instance || (this.instance = new Zuora(mainWindow, analytics, rest, paymentsProxyEndpoint));
	}
	public static Destroy = () => (Zuora.instance = null);

	private messageCallbackAttached = false;
	private successCallback!: MessageCallbackFunction;
	private errorCallback!: ErrorMessageCallback;
	private messageCallback = (event: Event & { data: ZuoraCallbackEventData; origin: string }) => {
		const hasValidOrigin = window.location.origin === event.origin;
		const shouldActOnEvent =
			this.messageCallbackAttached && event.data && hasValidOrigin && !event.data['pcmPixelPostMessageEvent'];

		if (shouldActOnEvent) {
			this.clearLongRunningSubmissionTimeout();
			this.removeMessageCallback();

			const eventData: ZuoraCallbackEventData = typeof event.data == 'object' ? event.data : JSON.parse(event.data);

			if (eventData.success == 'true') {
				this.successCallback(eventData);
			}
		}
	};

	private submissionDurationTimeout?: NodeJS.Timeout;

	private constructor(
		private mainWindow: Window,
		private analytics: IAnalytics,
		private rest: IRest,
		private paymentsProxyEndpoint: string
	) {}

	public Configure(successCallback: MessageCallbackFunction, errorCallback: ErrorMessageCallback): void {
		this.successCallback = successCallback;
		this.errorCallback = errorCallback;
	}

	public async RenderApplePay(accountId: string, amount: string): Promise<void> {
		await safelyExecute(async () => {
			const paymentSessionResponse = await this.rest.Post<{
				data: {
					type: string;
					id: string;
					attributes: {
						token: string;
					};
				};
			}>(
				`${this.paymentsProxyEndpoint}/api/payment-sessions`,
				JSON.stringify({
					accountId,
					amount
				})
			);
			if (!paymentSessionResponse.ok || !paymentSessionResponse.body.data.attributes.token) {
				alert('Failed to get payment session token from payments api. See console for details.');
				console.error('Status: ' + paymentSessionResponse.status);
				console.error('Errors: ' + paymentSessionResponse.errors);
				console.error('Body: ' + JSON.stringify(paymentSessionResponse.body));
			} else {
				const zuoraPayment = window['Zuora'](paymentSessionResponse.body.data.attributes.token);
				zuoraPayment
					.create('ApplePay', {
						environment: 'na_sandbox',
						paymentRequest: {
							country: 'US',
							currency: 'USD',
							totalPriceLabel: 'Testing',
							amount: amount,
							supportedNetworks: ['amex', 'jcb', 'masterCard', 'discover', 'visa']
						},
						style: {
							buttonStyle: 'black',
							buttonType: 'buy',
							locale: 'en-US'
						},
						onSuccess: function (response) {
							alert('Apple-Pay payment method created! See console for details.');
							console.info('paymentMethodId: ' + response.paymentMethodId);
							console.info('paymentId: ' + response.paymentId);
						},
						onError: function (response) {
							alert('Failed to create Apple-Pay payment method. See console for details.');
							console.error(response.code);
							console.error(response.message);
						}
					})
					.mount('#apple-pay-button');
			}
		});
	}

	public Render(params: RenderParams, prepopulateFields: CardPrepopulateFields | AchPrepopulateFields): void {
		const isConfigured = !!this.successCallback && !!this.errorCallback;
		if (!isConfigured) {
			throw new Error(NotConfiguredError);
		}

		safelyExecute(() => {
			this.mainWindow.Z.renderWithErrorHandler(
				params,
				prepopulateFields,
				this.successCallback,
				(key, code, message) => {
					this.clearLongRunningSubmissionTimeout();
					this.errorCallback(key, code, message);
				}
			);
		});
	}

	public AddMessageCallback(): void {
		if (!this.messageCallbackAttached) {
			this.mainWindow.addEventListener('message', this.messageCallback, false);
			this.messageCallbackAttached = true;
		}
	}

	public Validate(): Promise<boolean> {
		return new Promise((resolve) => {
			this.mainWindow.Z.validate((r) => {
				resolve(r.success);
			});
		});
	}

	public Submit(): void {
		safelyExecute(() => this.mainWindow.Z.submit());

		this.submissionDurationTimeout = setTimeout(() => {
			this.trackLongRunningSubmission();
		}, submissionDuration);
	}

	private removeMessageCallback(): void {
		this.mainWindow.removeEventListener('message', this.messageCallback, false);
		this.messageCallbackAttached = false;
	}

	private trackLongRunningSubmission(): void {
		this.analytics.TrackInternal({
			event_name: InternalEventNames.LongRunningSubmission,
			step: Steps.Four
		});
	}

	private clearLongRunningSubmissionTimeout(): void {
		if (this.submissionDurationTimeout) {
			clearTimeout(this.submissionDurationTimeout);
		}
	}
}
