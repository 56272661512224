import { getQueryParameter } from '../../../utilities/getQueryParameter';
import { ExtensionStatus } from './extensionStatus';
import { SessionStorageKeys } from '../browserStorage/keys/sessionStorageKeys';
import { CookieKeys, Cookies, ICookies } from '../cookies/cookies';
import { IRest, Rest } from '../rest/rest';
import { BrowserStorage, ISessionStorage } from '../browserStorage/browserStorage';
import { ITransaction } from '../../domain/transaction/ITransaction';
import { Transaction } from '../../domain/transaction/transaction';
import { DismissibleErrors, QueryParams } from '../../enums/module';

export interface IAmacIntegration {
	isEnabled: () => boolean;
	extendAccount: () => Promise<void>;
	Initialize: (
		isMemberFunc?: (transaction?: ITransaction) => Promise<boolean>,
		handleUserFunc?: () => void
	) => Promise<void>;
	extensionStatus: ExtensionStatus | undefined;
	messageUserBasedOffExtensionStatus: () => void;
}

export class AmacIntegration implements IAmacIntegration {
	private static amacCheckoutEnabled = false;
	private static instance: IAmacIntegration | null = null;
	private amacWorkerEndpoint = 'https://amac-worker.uscca.workers.dev/account-extension';
	private amacGuid: undefined | string = undefined;
	private constructor(
		private transaction: ITransaction,
		private cookieService: ICookies,
		private restService: IRest,
		private mainSessionStorage: ISessionStorage,
		private getQueryParameterFunc: typeof getQueryParameter
	) {}

	public static Instance(
		transaction = Transaction.Instance(),
		cookieService = Cookies.Instance(),
		restService = Rest.Instance(),
		mainSessionStorage = BrowserStorage.SessionInstance(),
		getQueryParameterFunc = getQueryParameter
	): IAmacIntegration {
		return (
			this.instance ||
			(this.instance = new AmacIntegration(
				transaction,
				cookieService,
				restService,
				mainSessionStorage,
				getQueryParameterFunc
			))
		);
	}

	public static Destroy = () => (AmacIntegration.instance = null);

	public static isEnabled(getQueryParameterFunc = getQueryParameter) {
		AmacIntegration.amacCheckoutEnabled =
			AmacIntegration.amacCheckoutEnabled || !!getQueryParameterFunc(QueryParams.AmacGuid);
		return AmacIntegration.amacCheckoutEnabled;
	}

	public isEnabled() {
		return AmacIntegration.isEnabled();
	}

	public extendAccount = async (): Promise<void> => {
		const accessToken = this.cookieService.get(CookieKeys.AccessToken);
		const response = await this.restService.Post(this.amacWorkerEndpoint, JSON.stringify({ guid: this.amacGuid }), {
			'X-Auth-Token': accessToken
		});
		if (response.ok) {
			this.setExtensionStatusInStorage(ExtensionStatus.Success);
		} else if (response.status === 409) {
			this.setExtensionStatusInStorage(ExtensionStatus.AlreadyRedeemed);
		} else {
			this.setExtensionStatusInStorage(ExtensionStatus.Error);
		}
	};

	public async Initialize() {
		const amacGuid = this.getQueryParameterFunc(QueryParams.AmacGuid);
		if (amacGuid) {
			AmacIntegration.amacCheckoutEnabled = true;
			this.amacGuid = amacGuid;
		}
	}

	public messageUserBasedOffExtensionStatus() {
		const messageToExtensionStatus: Partial<Record<ExtensionStatus, () => void>> = {
			[ExtensionStatus.AlreadyRedeemed]: () => {
				this.transaction.Error.Publish(DismissibleErrors.AMACExtensionAlreadyRedeemed);
			},
			[ExtensionStatus.Error]: () => {
				this.transaction.Error.Publish(DismissibleErrors.AMACExtensionEncounteredError);
			}
		};
		const messageUser = messageToExtensionStatus[this.extensionStatus];
		if (messageUser) {
			messageUser();
		}
	}

	get extensionStatus() {
		const status = this.mainSessionStorage.getItem(SessionStorageKeys.AMACExtensionStatus);
		return status || ExtensionStatus.RequestNotMade;
	}

	private setExtensionStatusInStorage(status: ExtensionStatus) {
		this.mainSessionStorage.setItem(SessionStorageKeys.AMACExtensionStatus, status);
	}
}
