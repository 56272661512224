import { FeatureFlagService } from '@delta-defense/feature-flag-service';
import { FeatureFlags } from '../core/enums/featureFlags';
import { Transaction } from '../core/domain/transaction/transaction';

const passwordPostCheckoutFunnelWhitelist = ['2813'];

export const passwordPostCheckoutIsEnabled = (
	funnelId = Transaction.Instance().Data.funnelId || '',
	features = FeatureFlagService.Instance()
): boolean =>
	passwordPostCheckoutFunnelWhitelist.includes(funnelId) ||
	features.IsEnabled(FeatureFlags.PasswordPostCheckout) ||
	features.IsEnabled(FeatureFlags.ElegantCheckout);
