import { Field, Transaction } from '../../../core/domain/module';
import { Step } from '../../../core/domain/transaction/step';
import { PaymentFrequencies } from '../../../core/enums/module';
import { FieldTypes } from '../../fieldTypes';
import { DefaultSteps } from '../../default';

export enum BillingFrequencyFields {
	Frequency = 'billingFrequency'
}

const fields = {
	[BillingFrequencyFields.Frequency]: new Field(
		BillingFrequencyFields.Frequency,
		'',
		FieldTypes.BillingFrequency,
		'',
		[]
	)
};

export const BillingFrequency = (transactionFunc = () => Transaction.Instance()) =>
	new Step(fields, async () => {
		const transaction = transactionFunc();
		const selectedFrequency = transaction.Steps[DefaultSteps.BillingFrequency].Fields[
			BillingFrequencyFields.Frequency
		].GetValue() as PaymentFrequencies;
		transaction.SetPurchaseProducts(selectedFrequency);

		return {
			wasSuccessful: !!transaction.Data.purchaseProducts?.primary
		};
	});
