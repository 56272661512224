export enum FeatureFlags {
	ElegantCheckout = 'elegant-checkout',
	TechnicalDifficulties = 'technical-difficulties-screen',
	ContentSecurityPolicy = 'checkout-content-security-policy',
	PtuPhaseOne = 'checkout-ptu-phase-1',
	CheckoutBonus = 'checkout-bonus',
	AddressValidation = 'checkout-address-validation',
	CheckoutBonusOptOut = 'checkout-bonus-item-opt-out',
	AddressAutocomplete = 'checkout-address-autocomplete',
	BundlesForMembers = 'checkout-member-bundle',
	PasswordPostCheckout = 'password-post-checkout',
	InternalOnlyDraftCMSContent = 'internal-only-draft-cms-content',
	CheckoutMobileSticky = 'checkout-mobile-sticky-button',
	PreventBonusItems = 'checkout-prevent-bonus-items',
	ScheduledMaintenance = 'scheduled-maintenance',
	AchPaymentOption = 'checkout-ach-payment-option'
}
