import { Step } from '../core/domain/transaction/step';
import { Elegant } from './steps/elegant';
import { EmptyStep } from './steps/default/emptyStep';
import { UpdatePassword } from './steps/default/updatePassword';

export enum ElegantSteps {
	Elegant = 'elegant',
	PostTransactionUpdatePassword = 'postTransactionUpdatePassword',
	PostTransactionReferral = 'postTransactionReferral'
}

export const elegantSteps: () => Record<ElegantSteps, Step> = () => ({
	[ElegantSteps.Elegant]: Elegant(),
	[ElegantSteps.PostTransactionUpdatePassword]: UpdatePassword(),
	[ElegantSteps.PostTransactionReferral]: EmptyStep()
});
