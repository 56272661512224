import { Routes } from './core/enums/routes';

export const SignUpRoutes: Routes[] = [
	Routes.Email,
	Routes.Elegant,
	Routes.SignupChart,
	Routes.SignupFrequency,
	Routes.SignupLevel,
	Routes.SignupNextPayment,
	Routes.SignupNextRegistration,
	Routes.SignupNextShipping,
	Routes.SignupPassword,
	Routes.SignupPayment,
	Routes.SignupRegistration,
	Routes.SignupShipping,
	Routes.SignupSpouse,
	Routes.SignupSummary,
	Routes.SignupVerification
];
